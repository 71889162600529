export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/about-me.png" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <p className="section--title">About</p>
          <h1 className="skills-section--heading">About Me</h1>
          <p className="hero--section-description">
            I’m Hamdi Lemamsha, a research consultant with almost a decade
            of experience. I have assisted over 150 clients,through their
            PhD, master’s and Bachelor's journeys, across more than
            25 different fields.
          </p>
          <p className="hero--section-description">
            My work has been recognised through more than four peer-reviewed
            articles in top journals. With my main research focus being
            the prevalence of obesity in Libya.
          </p>
        </div>
      </div>
    </section>
  );
}
